<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="true"></loading>
    <v-data-table
      :headers="headers"
      :items="news"
      :search="search"
      :server-items-length="total"
      :options.sync="serverOptions"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- <v-toolbar-title> -->
          <v-text-field
            v-model="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <!-- </v-toolbar-title> -->
          <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <!-- <div class="d-flex justify-space-between"> -->
              <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="close"
              >
                สร้างข่าวสาร
              </v-btn>
              <!-- </div> -->
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      v-if="editedItem.image_path"
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-img
                        label="รูปข่าวสาร"
                        :src="editedItem.image_path"
                      ></v-img>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-file-input
                        label="รูปข่าวสาร"
                        v-model="editedItem.file"
                        accept="image/png, image/jpeg, image/bmp"
                      ></v-file-input>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-checkbox
                        dense
                        hide-details
                        value="Y"
                        v-model="editedItem.status"
                        label="ประกาศแจ้งเตือนทันที"
                      ></v-checkbox>

                    </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.title"
                        label="หัวข้อข่าว"
                      ></v-text-field>
                      <small
                        class="validate-err"
                        v-for="(err,index) in v$.editedItem.title.$silentErrors"
                        v-if="v$.editedItem.title.$error">{{ index > 0 ? ', ' : '' }}
                        {{ err.$message }}</small>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-textarea
                        v-model="editedItem.content"
                        placeholder="รายละเอียด"
                        label="รายละเอียด"></v-textarea>
                      <small
                        class="validate-err"
                        v-for="(err,index) in v$.editedItem.content.$silentErrors"
                        v-if="v$.editedItem.content.$error">{{ index > 0 ? ', ' : '' }}
                        {{ err.$message }}</small>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogDelete"
            max-width="600px"
          >
            <v-card>
              <v-card-title class="text-h5 mb-5">
                Are you sure you want to delete this item?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  outlined
                  @click="closeDelete"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  @click="deleteItemConfirm"
                >
                  OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          {{ icons.mdiPencil }}
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          {{ icons.mdiDelete }}
        </v-icon>
      </template>

      <template v-slot:item.index="{ index }">
        {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-container
          class="px-0"
          fluid
        >
          <v-checkbox
            value="Y"
            @change="changeStatus(item.id)"
            v-model="item.status"
          ></v-checkbox>
        </v-container>
      </template>
      <template v-slot:item.labelStatus="{ item }">
        <span v-if="item.status =='Y'">เผยแพร่</span>
        <span v-if="item.status !='Y'">ปิดประกาศ</span>
      </template>
      <template v-slot:item.image="{ item }">
        <v-img
          class="mt-2 mb-2"
          width="100"
          :src="item.image_path"
        ></v-img>
      </template>
      <template v-slot:no-data>
        <!-- <v-btn
          color="primary"
          @click="initialize"
        >
          Reset
        </v-btn> -->
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_news from '../../../services/news'
import Swal from "sweetalert2";
import {useVuelidate} from '@vuelidate/core'
import {required, email, minLength} from '@vuelidate/validators'

// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.min.css';

export default {
  components: {Loading},
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions: {
      page: 1,
      itemsPerPage: 10,
    },
    total: 0,
    isLoading: false,
    news: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false
      },
      {text: 'รูป/ภาพ', value: 'image', sortable: false},
      {text: 'หัวข้อข่าวสาร', value: 'title'},
      {text: 'เผยแพร่', value: 'labelStatus'},
      {text: 'วันที่อัพเดท', value: 'updated_at'},
      {text: 'สถานะ (เปิด/ปิด)', value: 'status'},
      {text: 'สร้างโดย', value: 'employee.employee_name', sortable: false},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      file: null,
      status: '',
      title: '',
      employee: '',
      updated_at: '',
    },
    defaultItem: {
      file: null,
      status: '',
      title: '',
      employee: '',
      updated_at: '',
    },
  }),
  validations() {
    return {
      editedItem: {
        title: {required},
        content: {required},
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม ข่าวสาร' : 'แก้ไข ข่าวสาร'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getNews()
      },
    },
    search: {
      handler() {
        this.getNews()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    this.getNews()
  },

  methods: {
    changeStatus(id) {

      instance_news.newsChangeStatus(id).then(res => {
        Swal.fire({
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500
        })
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
      });
    },
    getNews() {
      this.$store.state.isLoading = true;
      var data = {
        search: this.search,
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        sortBy: this.sortBy ?? "",
        sortDesc: this.sortDesc ?? ''
      }

      instance_news.get(data).then(res => {
        this.news = res.data.data.data
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false;
      }).catch(err => {
        this.$store.state.isLoading = false;

      });
    },
    editItem(item) {
      this.v$.$reset();
      this.editedIndex = this.news.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.news.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.$store.state.isLoading = true;
      instance_news.newsDestroy(this.editedItem.id)
        .then(res => {
          setTimeout(function () {
            Swal.fire({
              icon: 'success',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500
            })
          }, 150)
          this.getNews();
          this.closeDelete()
        }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })

        // this.closeDelete()
      });
    },

    close() {
      this.v$.$reset();
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      this.$store.state.isLoading = true;

      if (this.editedIndex > -1) {
        instance_news.newsUpdate(this.editedItem)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            }, 150)
            this.getNews()
            this.close()
          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })

          // this.close()
        });
      } else {
        instance_news.newsStore(this.editedItem)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            }, 150);
            this.getNews()
            this.close()

          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })

        });
      }
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
