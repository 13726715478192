/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

const config = {
  header: {
    'Content-Type': 'multipart/form-data'
  }
}

export default {
  get(data) {

    return api.get(`news/list?search=${data.search}&date=${data.date}&status=${data.status}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  getUserNews(data) {

    return api.get(`user/news_list?page=${data.page}`)
  },

  showPoint(data){
    return api.get(`news/show?search=${data.search}&date=${data.date}&status=${data.OrderStatus}`)
  },

  newsStore(data) {
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }

    return api.post(`news/store`, params, config)
  },

  newsUpdate(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    return api.post(`news/update/${data.id}`, params, config)
  },

  newsDestroy(id) {
    // let getFormData = data

    let params = new FormData();

    return api.delete(`news/delete/${id}`, params, {})
  },

  newsChangeStatus(id)
  {

    let params = new FormData();
    params.append('_method', 'PUT');

    return api.post(`news/new_updateStatus/${id}`, params, {})
  }

}
